
import { Box, Container } from '@mui/material';
import './App.css';
import Message from './components/common/Message';
import TopNavbar from './components/common/TopNavbar';
import BgImg from './img/bg2.jpg';

function App() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${BgImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        paddingBottom: '15vh',
        width: '100%', // ensure it takes full width
      }}
    >
      <header className="App-header">
        <TopNavbar />
      </header>
      <Container>
        <Message />
      </Container>
    </Box>
  );
}

export default App;
