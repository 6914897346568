import * as React from 'react';
import { Box, Typography, ListItem, Container } from "@mui/material";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';

const AnimatedText = ({ text }) => {
  const [visibleLetters, setVisibleLetters] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setVisibleLetters((prev) => (prev < text.length ? prev + 1 : 0));
    }, 200); // Adjust timing as needed

    return () => clearInterval(interval);
  }, [text]);

  return (
    <Typography
      variant="h2"
      component="h2"
      sx={{
        textAlign: 'center',
        height: '30vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: { xs: '20px', md: '36px' },
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
      }}
    >
      {text.split('').map((letter, index) => (
        <span
          key={index}
          style={{
            display: 'inline-block',
            opacity: index < visibleLetters ? 1 : 0,
            transition: 'opacity 0.5s ease', // Smooth transition
          }}
        >
          {letter === ' ' ? '\u00A0' : letter} {/* Non-breaking space for layout */}
        </span>
      ))}
    </Typography>
  );
};

export default function Message() {
  return (
    <Container maxWidth="lg">
      <Box>
        <AnimatedText text="The website is under development..." />
      </Box>
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{
            textAlign: 'center',
            fontSize: { xs: '24px', md: '30px' },
            fontWeight: '700',
            padding: '15px'
          }}
        >
          Visit our outlets
        </Typography>
      </Box>
      <Divider />
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: { xs: 'column', md: 'row' },
        minWidth: '100%'
      }}>
        <Grid container minWidth='100%' maxWidth='md' justifyContent='space-around' spacing={2}>
          <Grid item xs={12} md={6} maxWidth='300px'>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start !important',
                alignItems: 'flex-start !important',
                width: 'auto !important'
              }}
            >
              <h4 style={{ fontSize: '24px', color: '#1b70bb' }}>Mohammadpur Branch:</h4>
              <Typography variant="body1" component="p"
                sx={{
                  textAlign: 'left',
                  fontSize: '20px'
                }}
              >
                2/3, Noorjahan Road
              </Typography>
              <Typography variant="body1" component="p"
                sx={{
                  textAlign: 'left',
                  paddingTop: '5px',
                  fontSize: '20px'
                }}
              >
                Mohammadpur, Dhaka-1207
              </Typography>
              <Typography variant="body1" component="p"
                sx={{
                  textAlign: 'left',
                  paddingTop: '5px',
                  fontSize: '20px'
                }}
              >
                Mobile: 01601-556447
              </Typography>
            </ListItem>
          </Grid>
          <Grid item xs={12} md={6} maxWidth='300px'>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start !important',
                alignItems: 'flex-start !important',
                width: 'auto !important'
              }}
            >
              <h4 style={{ fontSize: '24px', color: '#1b70bb' }}>Dhanmondi Branch:</h4>
              <Typography variant="body1" component="p"
                sx={{
                  textAlign: 'left',
                  fontSize: '20px'
                }}
              >
                25/4 Jigatola
              </Typography>
              <Typography variant="body1" component="p"
                sx={{
                  textAlign: 'left',
                  paddingTop: '5px',
                  fontSize: '20px'
                }}
              >
                Dhanmondi, Dhaka-1207
              </Typography>
              <Typography variant="body1" component="p"
                sx={{
                  textAlign: 'left',
                  paddingTop: '5px',
                  fontSize: '20px'
                }}
              >
                Mobile: 01601-556447
              </Typography>
            </ListItem>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
