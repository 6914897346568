import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Logo from './../../img/logo-black.png';

function TopNavbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="static"
      sx={{backgroundColor:'#fff'}}
    >
      <Container maxWidth="xl"
        sx={{display:'flex', alignItems:'center', justifyContent:'center'}}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              width: {xs: '80px', md: '100px'},
              padding: '20px',
              margin: 'auto'
            }}
          >
            <img
              style={{
                width: '100%',
                margin: '0 auto'
              }}
                src={Logo} alt="Prinaz Logo"
              />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                </MenuItem>
              ))} */}
            </Menu>
          </Box>
          

          <Box
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              width: {xs: '100px', md: '150px'},
              padding: '30px',
              margin: 'auto',
            }}
          >
            <img
              style={{
                width: '100%',
                margin: '0 auto'
              }}
                src={Logo} alt="Prinaz Logo"
              />
          </Box>
        
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default TopNavbar;
